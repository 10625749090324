export class Answer {
    public constructor(public answer: string, public isRight: boolean) {

    }

    public static factory(rawAnswers: any): Answer[] {
        let answers: Answer[] = [];
        for(let i = 0; i < 4; i++) {
            answers.push(new Answer(
                rawAnswers[i].answer,
                rawAnswers[i].is_right
            ));
        }
        return answers;
    }

    public static getResponse(answers: Answer[]): any {
        return [
            {
                'answer': answers[0].answer,
                'is_right': answers[0].isRight
            },
            {
                'answer': answers[1].answer,
                'is_right': answers[1].isRight
            },
            {
                'answer': answers[2].answer,
                'is_right': answers[2].isRight
            },
            {
                'answer': answers[3].answer,
                'is_right': answers[3].isRight
            }
        ]
    }
}