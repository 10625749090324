import { Observable } from 'rxjs';
import { Http } from '@angular/http';
import { Answer } from './../entity/Answer';
import { Quiz } from './../entity/Quiz';
import { Router } from '@angular/router';
import { Headers } from '@angular/http';
import { Injectable, Inject } from '@angular/core';
import { retry, map } from 'rxjs/operators';

@Injectable()
export class QuizServiceService {
  private api = 'https://bildungsapi.waymark.at';
  constructor(
    private http: Http,
    private router: Router) {
  }
  public getQuizzeForModule(mid: number): Observable<Quiz[]> {
    const headers: Headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.api + '/quiz/' + mid, { headers: headers })
    .pipe(retry(3), map((result) => {
      let rawQuizze = result.json().quizze;
      let quizze: Quiz[] = [];
      for(let i = 0; i < rawQuizze.length; i++) {
        quizze.push(Quiz.factory(rawQuizze[i]));
      }
      return quizze;
    }));
  }

  public createQuiz(quiz: Quiz): Observable<boolean> {
    const headers: Headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('token', localStorage.getItem('token'));
    return this.http
    .post(this.api + "/quiz", JSON.stringify(
      {
        'quiz':
        {
          'mid': quiz.getMid(),
          'question' : quiz.question,
          'answers' : Answer.getResponse(quiz.answers)
        }
      }
    ), { headers: headers })
    .pipe(retry(3), map((result) => {
        if (result.status === 201) {
          return true;
        } else {
          return false;
        }
    }));
  }
  public updateQuiz(quiz: Quiz): Observable<boolean> {
    const headers: Headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('token', localStorage.getItem('token'));
    return this.http
    .put(
      this.api + '/quiz/' + quiz.qid,
      JSON.stringify({
        'quiz':
        {
          'question' : quiz.question,
          'answers' : Answer.getResponse(quiz.answers)
        }
      }),
      { headers: headers }
    )
    .pipe(retry(3), map((result) => {
        if (result.status === 201) {
          return true;
        } else {
          return false;
        }
    }));
  }
  public removeQuiz(qid: number): Observable<boolean> {
    const headers: Headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('token', localStorage.getItem('token'));
    return this.http
    .delete(this.api + '/quiz/' + qid, { headers: headers })
    .pipe(retry(3), map(result => true));
  }
  private errorHandler(error: Error |any): Observable<any> {
      return Observable.throw(error);
  }
}
