import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quiz-module-form',
  templateUrl: './quiz-module-form.component.html',
  styleUrls: ['./quiz-module-form.component.css']
})
export class QuizModuleFormComponent implements OnInit {
  private modules: string[] = [
    "Innerstaatliches Organisationsrecht",
    "Allgemeines Verwaltungsverfahren",
    "Grundrechte in der Wirtschaft",
    "Gewerbeordnung",
    "Wirtschaftsstrafrecht"
  ]
  private selectedSubject: string;
  @Output()  createQuizFirstStep = new EventEmitter<String>();
  constructor() { }

  ngOnInit() {
  }

  submitForm(value, form) {
    if(this.selectedSubject) {
      this.createQuizFirstStep.emit(this.selectedSubject);
    }
  }

}
