import { Quiz } from './../../entity/Quiz';
import { Component, OnInit } from '@angular/core';
import { QuizServiceService } from 'src/app/service/quiz-service.service';
import { Answer } from 'src/app/entity/Answer';

@Component({
  selector: 'app-quiz-container',
  templateUrl: './quiz-container.component.html',
  styleUrls: ['./quiz-container.component.css']
})
export class QuizContainerComponent implements OnInit {
  private quizze: Quiz[];
  private isCreating: boolean = false;
  private updateProcess: boolean = false;
  private createdModule: string;
  private quiz = new Quiz(0, "Innerstaatliches Organisationsrecht", '', [new Answer('', true), new Answer('', false), new Answer('', false), new Answer('', false)]);
  private selectedModule: string;
  constructor(private qService: QuizServiceService) { }

  ngOnInit() {
  }

  getTable(value) {
    this.selectedModule = value;
    this.qService.getQuizzeForModule(Quiz.getMidForName(value)).subscribe((quizze) => this.quizze = quizze);
  }

  createQuizFirstStep(moduleName) {
    this.isCreating = true;
    this.updateProcess = false;
    this.createdModule = moduleName;
  }

  updateQuizFirstStep(quiz) {
    this.isCreating = true;
    this.updateProcess = true;
    this.quiz = quiz;
  }

  createQuiz(quiz) {
    this.isCreating = false;
    let resQuiz: Quiz = quiz;
    resQuiz.moduleName = this.createdModule;
    this.qService.createQuiz(resQuiz).subscribe(() => {
      if(this.selectedModule) {
        this.qService.getQuizzeForModule(Quiz.getMidForName(this.selectedModule)).subscribe((quizze) => this.quizze = quizze);
      }
    });
  }

  updateQuiz(quiz) {
    this.isCreating = false;
    this.qService.updateQuiz(quiz).subscribe(() => {
      this.quiz = new Quiz(0, "Innerstaatliches Organisationsrecht", '', [new Answer('', true), new Answer('', false), new Answer('', false), new Answer('', false)]);
      if(this.selectedModule) {
        this.qService.getQuizzeForModule(Quiz.getMidForName(this.selectedModule)).subscribe((quizze) => this.quizze = quizze);
      }
    });
  }

  updateTable(value) {
    if(this.selectedModule) {
      this.qService.getQuizzeForModule(Quiz.getMidForName(this.selectedModule)).subscribe((quizze) => this.quizze = quizze);
    }
  }
}
