import { QuizServiceService } from './../../service/quiz-service.service';
import { Answer } from './../../entity/Answer';
import { Quiz } from './../../entity/Quiz';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-quiz-creation-form',
  templateUrl: './quiz-creation-form.component.html',
  styleUrls: ['./quiz-creation-form.component.css']
})
export class QuizCreationFormComponent implements OnInit {
  @Input() quiz: Quiz;
  @Input() isCreation: boolean;
  @Output()  createQuiz = new EventEmitter<Quiz>();
  @Output()  updateQuiz = new EventEmitter<Quiz>();
  constructor(private qService: QuizServiceService) { 
    this.quiz = new Quiz(0, "Innerstaatliches Organisationsrecht", '', [new Answer('', true), new Answer('', false), new Answer('', false), new Answer('', false)]);
  }

  ngOnInit() {

  }

  submitForm(value, form) {
    if (
      (this.quiz.answers[0].answer !== '') &&
      (this.quiz.answers[1].answer !== '') &&
      (this.quiz.answers[2].answer !== '') &&
      (this.quiz.answers[3].answer !== '') &&
      this.quiz.question !== '') {
        if(this.isCreation) {
          this.createQuiz.emit(this.quiz);
        } else {
          this.updateQuiz.emit(this.quiz);
        }
    }
  }
}
