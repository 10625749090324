import { AuthGuardService } from './service/auth-guard.service';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { QuizContainerComponent } from './quiz/quiz-container/quiz-container.component';
import { QuizTableComponent } from './quiz/quiz-table/quiz-table.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: QuizContainerComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    component: LoginFormComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
