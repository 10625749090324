import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quiz-search-bar',
  templateUrl: './quiz-search-bar.component.html',
  styleUrls: ['./quiz-search-bar.component.css']
})
export class QuizSearchBarComponent implements OnInit {
  private modules: String[] = [
    'Innerstaatliches Organisationsrecht',
    'Allgemeines Verwaltungsverfahren',
    'Grundrechte in der Wirtschaft',
    'Gewerbeordnung',
    'Wirtschaftsstrafrecht'
  ];
  private selectedModule: String;
  @Output() searchSelected = new EventEmitter<String>();
  constructor() { }

  ngOnInit() {
  }

  contentChanged(value) {
    this.searchSelected.emit(value);
  }
}
