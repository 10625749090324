import { Answer } from './Answer';
export class Quiz {
    constructor(public qid: number, public moduleName: string, public question: string, public answers: Answer[]) {

    }

    public getMid(): number {
        return Quiz.getMidForName(this.moduleName);
    }

    public static getMidForName(name: string): number {
        switch(name) {
            case "Innerstaatliches Organisationsrecht": 
                return 1;
            case 'Allgemeines Verwaltungsverfahren':
                return 2;
            case "Grundrechte in der Wirtschaft":
                return 3;
            case "Gewerbeordnung":
                return 4;
            default: 
                return 5;
        }
    }

    public static factory(rawQuiz: any): Quiz {
        return new Quiz(
            rawQuiz.qid,
            rawQuiz.module,
            rawQuiz.question,
            Answer.factory(rawQuiz.answers)
        );
    }
}