import { Router } from '@angular/router';
import { LoginService } from './../../service/login.service';
import { AuthGuardService } from './../../service/auth-guard.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  constructor(private lService: LoginService, private router: Router) { }

  ngOnInit() {
  }

  submitForm(value: any) {
    if(this.lService.login(value.username, value.password)) {
        this.router.navigate(['/home']);
    }
  }
}
