import { Injectable } from '@angular/core';

@Injectable()
export class LoginService {

  constructor() {

  }

  public login(username: string, password: string): boolean {
    if(username === "henning" && password === "!Henning123") {
      localStorage.setItem('token', "A123bvad!");
      return true;
    }
    return false;
  }

  public isLoggedIn(): boolean {
     if (localStorage.getItem('token')) {
       return true;
     }
     return false;
   }
}
