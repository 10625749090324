import { LoginService } from './service/login.service';
import { AuthGuardService } from './service/auth-guard.service';
import { QuizServiceService } from './service/quiz-service.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { QuizTableComponent } from './quiz/quiz-table/quiz-table.component';
import { QuizContainerComponent } from './quiz/quiz-container/quiz-container.component';
import { QuizSearchBarComponent } from './quiz/quiz-search-bar/quiz-search-bar.component';
import { QuizCreationFormComponent } from './quiz/quiz-creation-form/quiz-creation-form.component';
import { QuizModuleFormComponent } from './quiz/quiz-module-form/quiz-module-form.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { LoadingComponent } from './login/loading/loading.component';

@NgModule({
  declarations: [
    AppComponent,
    QuizTableComponent,
    QuizContainerComponent,
    QuizSearchBarComponent,
    QuizCreationFormComponent,
    QuizModuleFormComponent,
    LoginFormComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpModule
  ],
  providers: [
    QuizServiceService,
    AuthGuardService,
    LoginService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
