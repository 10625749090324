import { Quiz } from './../../entity/Quiz';
import { QuizServiceService } from './../../service/quiz-service.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quiz-table',
  templateUrl: './quiz-table.component.html',
  styleUrls: ['./quiz-table.component.css']
})
export class QuizTableComponent implements OnInit {
  @Input() quizze: Quiz[];
  @Output() updateQuizFirstStep = new EventEmitter<Quiz>();
  @Output() updateTable = new EventEmitter<void>();
  constructor(private qService: QuizServiceService) { }

  ngOnInit() {
  }

  removeContent(qid) {
    this.qService.removeQuiz(qid).subscribe(() => {
      this.updateTable.emit();
    });
  }

  getUpdateQuizForm(quiz) {
    this.updateQuizFirstStep.emit(quiz);
  }

}
